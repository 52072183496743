import React, { Component } from 'react'
import ReactLoading from 'react-loading'
import Header from '../../components/header'
import Footer from '../../components/footer'
import api from '../../services/api'
import swal from 'sweetalert'
import headers from '../../common/parameters/headers'
import msg from '../../common/strings/messages'
import txt from '../../common/strings/appStrings'
import currencySymbols from '../../common/constants/currency-symbols'

import './billing.css'

class Billing extends Component {
    constructor (props) {
        super(props)

        this.creditAmount = React.createRef()
        this.state = {
            loadingPayment: false
        }

        this.redeemCoupon = this.redeemCoupon.bind(this)
    }

    refreshPage = () => {
        window.location.reload(true)
    }

    redeemCoupon() {
        var couponNiceName = document.getElementById('coupon-nice-name-input').value
        
        if(couponNiceName === undefined || couponNiceName === ''){
            return
        }

        api.get('payment/coupon-redeem/' + couponNiceName, {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Accept': headers.Accept,
                'Content-Type': headers.Content_Type
            }
        }).then(resp => {
            var dialogTitle
            var dialogText

            if(resp.status === 200) {
                dialogTitle = msg.titleCouponRedeemSuccess

                if(resp.data.code === 'couponRedeemSuccess' && resp.data.amountCreditsAdded) {
                    dialogText = msg.couponRedeemSuccessPart1
                    dialogText += resp.data.amountCreditsAdded
                    dialogText += msg.couponRedeemSuccessPart2
                }
                else {
                    dialogText = msg.couponRedeemSuccessGeneric
                }

                swal({
                    title: dialogTitle,
                    text: dialogText,
                    icon: 'success',
                    buttons: {
                        confirm: true
                    }
                }).then(resp => {
                    this.refreshPage()
                })
            }
        }).catch(error => {
            var dialogTitle
            var dialogText
        
            if(error.response && error.response.status === 400) {
                switch (error.response.data.code) {
                    case 'invalidCoupon':
                        dialogTitle = msg.titleInvalidCoupon
                        dialogText = msg.invalidCoupon
                        break;
                    
                    case 'couponAlreadyTaken':
                        dialogTitle = msg.titleCouponAlreadyTaken
                        dialogText = msg.couponAlreadyTaken
                        break;
                    
                    default:
                        dialogTitle = error.response.data.code
                        dialogText = error.response.data.message
                }
            }
            else{
                dialogTitle = msg.titleUnknownError
                dialogText = msg.unknownError
            }
            
            swal({
                title: dialogTitle,
                text: dialogText,
                icon: 'error',
                buttons: {
                    confirm: true
                }
            })
        })
    }

    updateTotalValue() {
        var creditAmount = document.getElementById('credit-amount').value
        var pricePerCredit = localStorage.getItem('pricePerCredit')
        var currencyCode = localStorage.getItem('currencyCode')

        if(isNaN(creditAmount) || isNaN(parseInt(creditAmount)) || parseInt(creditAmount) <= 0){
            document.getElementById('total-value').textContent = '-'
            return
        }

        if(pricePerCredit === null || isNaN(parseFloat(pricePerCredit)) || parseFloat(pricePerCredit) === -1) {
            document.getElementById('total-value').textContent = '-'
            return
        }

        var currencySymbol = currencySymbols[currencyCode]

        if(typeof currencySymbol == 'undefined') {
            document.getElementById('total-value').textContent = '-'
            return
        }

        var totalValue = creditAmount * parseFloat(pricePerCredit)
        document.getElementById('total-value').textContent = currencySymbol + totalValue.toFixed(2)
    }

    creditAmountChangeHandler = (event) => {
        this.updateTotalValue()
    }

    generateOrderId = (creditAmount) => {
        if(typeof creditAmount === 'undefined' || creditAmount == null){
            throw new Error('Credit amount is null')
        }
        else if(isNaN(creditAmount)  || isNaN(parseInt(creditAmount)) || parseInt(creditAmount) <= 0){
            throw new Error('Invalid credit amount: ' + creditAmount)
        }

        const data = {
            'creditAmount': parseInt(creditAmount)
        }
        
        return api.post('payment/paypal/init', data, {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Accept': headers.Accept,
                'Content-Type': headers.Content_Type
            }
        }).then(resp => {
            if(typeof resp.data.transactionId != 'undefined' && resp.data.transactionId != null) {
                var transactionId = resp.data.transactionId
                
                if(localStorage.getItem('transactionId') !== null) {
                    localStorage.removeItem('transactionId')
                }

                localStorage.setItem('transactionId', transactionId)
                return transactionId
            }
            
            swal({
                title: msg.titleUnknownError,
                text: msg.unknownError,
                icon: 'error',
                buttons: {
                    confirm: true
                }
            })

            throw new Error('Unexpected error while generating transactionId')
        }).catch(error => {
            if(error.response && error.response.status === 400) {
                swal({
                    title: error.response.data.code,
                    text: error.response.data.code,
                    icon: 'error',
                    buttons: {
                        confirm: true
                    }
                }).then(resp => {
                    this.refreshPage()
                })
            }

            throw new Error('Unexpected error ocurred')
        })
    }

    finishOrder = (transactionId) => {
        const data = {
            'transactionId': transactionId
        }

        return api.post('payment/paypal/finish', data, {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Accept': headers.Accept,
                'Content-Type': headers.Content_Type
            }
        }).then(resp => {
            if(resp.status === 200) {
                return true;
            }

            return false;
        }).catch(function(error) {
            return false
        })
    }

    updatePricePerCredit() {
        return api.get('payment/pricing', {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Accept': headers.Accept,
                'Content-Type': headers.Content_Type
            }
        }).then(resp => {
            var pricePerCredit
            var currencyCode

            if(resp.status === 200) {
                pricePerCredit = resp.data.pricePerCredit
                currencyCode = resp.data.currencyCode
            }
            else{
                pricePerCredit = -1
                currencyCode = '?'
            }

            localStorage.setItem('pricePerCredit', pricePerCredit)
            localStorage.setItem('currencyCode', currencyCode)
        }).catch(function(error) {
            localStorage.setItem('pricePerCredit', -1)
            localStorage.setItem('currencyCode', '?')
        })
    }

    updatePricePerCreditLabel() {
        var pricePerCredit = localStorage.getItem('pricePerCredit')
        var currencyCode = localStorage.getItem('currencyCode')

        if(pricePerCredit === null || isNaN(parseFloat(pricePerCredit)) || parseFloat(pricePerCredit) === -1) {
            document.getElementById('price-per-credit').textContent = '-'
            return
        }

        var currencySymbol = currencySymbols[currencyCode]

        if(typeof currencySymbol == 'undefined') {
            document.getElementById('price-per-credit').textContent = '-'
            return
        }

        var totalValue = 1 * parseFloat(pricePerCredit)
        var pricePerCreditelement = document.getElementById('price-per-credit')

        if(pricePerCreditelement) {
            pricePerCreditelement.textContent = currencySymbol + totalValue.toFixed(2)
        }
    }

    componentDidMount() {
        var _this = this
        // this.updatePricePerCredit().then(() => {
        //     this.updatePricePerCreditLabel()
        //     this.updateTotalValue()
        // })

        var loadPaypalScript = function (src) {
            var tag = document.createElement('script')
            tag.async = false
            tag.src = src
            tag.onload = () => {
                window.paypal.Buttons({
                    onInit: function(data, actions) {
                        actions.disable()

                        document.querySelector('#credit-amount').addEventListener('change', function(event) {
                            if(!isNaN(event.target.value) && !isNaN(parseInt(event.target.value)) && parseInt(event.target.value) > 0) {
                                actions.enable()
                            }
                            else{
                                actions.disable()
                            }
                        })
                        
                        var creditAmount = document.getElementById("credit-amount").value
                        if(!isNaN(creditAmount) && !isNaN(parseInt(creditAmount)) && parseInt(creditAmount) > 0){
                            actions.enable()
                        }
                    },

                    createOrder: function(data, actions) {
                        var creditAmount = document.getElementById("credit-amount").value
                        var transactionIdPromise = _this.generateOrderId(creditAmount)
                        return transactionIdPromise
                    },

                    onApprove: function(data, actions) {
                        _this.setState({loadingPayment: true})
                        return actions.order.capture().then(function(details) {
                            var transactionId = localStorage.getItem('transactionId')
                            localStorage.removeItem('transactionId')

                            if(transactionId != null) {
                                _this.finishOrder(transactionId).then(success => {
                                    var message
                                    if(success === true) {
                                        message = {
                                            title: msg.titlePaypalPaymentSuccess,
                                            text: msg.paypalPaymentSuccess,
                                            icon: 'success',
                                            buttons: {
                                                confirm: true
                                            }
                                        }
                                    }
                                    else{
                                        message = {
                                            title: msg.titlePaypalPaymentError,
                                            text: msg.paypalPaymentError,
                                            icon: 'error',
                                            buttons: {
                                                confirm: true
                                            }
                                        }
                                    }

                                    _this.setState({loadingPayment: false})
                                    swal(message).then(resp => {
                                        _this.refreshPage()
                                    })
                                })
                            }
                            else{
                                _this.setState({loadingPayment: false})
                            }
                        })
                    },

                    onError: function(err) {
                        localStorage.removeItem('transactionId')
                        swal({
                            title: msg.titlePaypalPaymentError,
                            text: msg.paypalPaymentError + ': ' + err,
                            icon: 'error',
                            buttons: {
                                confirm: true
                            }
                        }).then(resp => {
                            this.refreshPage()
                        })
                    },

                    onCancel: function(data, actions) {
                        localStorage.removeItem('transactionId')
                    }
                }).render('#paypal-button-container')
            }
            var body = document.getElementsByTagName('body')[0]
            body.appendChild(tag)
        }
        
        // loadPaypalScript('https://www.paypal.com/sdk/js?client-id=Aef1F8GHcUAxzow05K-db9P5OL7YeB0zUTZGf1vPZP3Kx007C49Bbg2ogT9tl4KXDCgToWOCm8lNu-Pz&currency=BRL')
    }

    render() {
        return (
            <section id='billing-section'>
                <Header />
                <div id='body'>
                    <article>
                        <legend>{txt.billing}</legend>
                        {/* <hr/>
                        <p>Insira a quantidade de créditos que deseja adquirir e pague clicando em um dos meios de pagamento disponíveis abaixo</p>
                        <br/>
                        <div>
                            <label>Quantidade de créditos a adquirir:</label><br/>
                            <input type="number" id="credit-amount" ref={this.creditAmount} onChange={this.creditAmountChangeHandler} step="1" min="1" placeholder="0"></input>
                            <label>créditos</label><br/><br/><br/>
                            <p>Preço por crédito: <span id='price-per-credit'></span></p>
                            <p><b>Total a pagar: <span id='total-value'></span></b></p><br/><br/>
                            <div id="paypal-button-container" style={{width: '300px'}}></div>
                        </div> */}
                        <hr/>
                        <div>
                            <legend>{txt.coupon}</legend><br/>
                            <input className="coupon-input" name="coupon-input" id="coupon-nice-name-input" placeholder="CODEPEACE10"></input>
                            <button className="btn-redeem-coupon"onClick={this.redeemCoupon}>Resgatar</button>
                            <br/><br/>
                        </div>
                    </article>
                    <div hidden={!this.state.loadingPayment} className='payment-overlay'>
                        <div className='overlay-content-box'>
                            <p><b>Aguarde</b></p>
                            <p>Seu pagamento está sendo processado</p><br/><br/><br/><br/><br/><br/><br/><br/>
                            <div className='payment-wheel'>
                                <ReactLoading type={'spin'} color={'#43B6AE'} />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        )
    }

}

export default Billing