import React, { Component } from 'react'
import swal from 'sweetalert'

import api from '../../services/api'
import headers from '../../common/parameters/headers'
import msg from '../../common/strings/messages'
import logo from '../../assets/codepeace-logo.svg'
import termsOfUsePDF from '../../assets/terms-of-use.pdf'


import './auth.css'

class Auth extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loginMode: true,
            recoverPasswordMode: false,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            passwordMatch: false,
            loading: false,
            termsOfUseAccepted: false
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    // clearLogin = () => {
    //     document.getElementById('login-form').reset()
    //     this.setState({
    //         firstName: '',
    //         lastName: '',
    //         email: '',
    //         password: ''
    //     })
    // }

    // clearSign = () => {
    //     document.getElementById('login-form').reset()
    //     this.setState({
    //         email: '',
    //         password: ''
    //     })
    // }

    enableRecoverPasswordMode() {
        this.setState({ recoverPasswordMode: true })
        this.setState({ loginMode: false })
    }

    enableLoginMode() {
        this.setState({ loginMode: true })
        this.setState({ recoverPasswordMode: false })
    }

    enableSignupMode() {
        this.setState({ loginMode: false })
        this.setState({ recoverPasswordMode: false })
    }

    handleTermsOfUse = e => {
        var checkbox = document.getElementById('terms-of-use-checkbox')
        
        if(checkbox.checked) {
            this.setState({ termsOfUseAccepted: true })
        }
        else{
            this.setState({ termsOfUseAccepted: false })
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleConfirmPassword = e => {
        if (e.target.value === this.state.password) {
            this.setState({ passwordMatch: true })
        }
        else {
            this.setState({ passwordMatch: false })
        }
    }

    handleSubmit(values) {
        values.preventDefault()

        if(this.state.loginMode && !this.state.recoverPasswordMode){
            this.login()
        }
        else if(this.state.recoverPasswordMode) {
            this.recoverPassword()
        }
        else{
            this.signup()
        }
    }

    refreshPage = () => {
        window.location.reload(true)
    }

    async recoverPassword() {
        const { history } = this.props

        const data = {
            email: this.state.email,
        }

        await api.post('account/recover', data, {
            headers: {
                'Content-Type': headers.Content_Type
            }
        }).then(async resp => {
            swal({
                title: msg.titleRecoverEmailSent,
                text: msg.recoverEmailSent,
                icon: 'success'
            })
            .then(() => {
                history.push('/login')
            })
        }).catch(function (error) {
            swal({
                title: msg.titleRecoveryAttemptFailed,
                text: msg.recoveryAttemptFailed,
                icon: 'error'
            })
            .then(() => {
                history.push('/login')
            })
        })
    }

    async login() {
        const { history } = this.props

        const data = {
            email: this.state.email,
            password: this.state.password
        }

        await api.post('account/login', data, {
            headers: {
                'Content-Type': headers.Content_Type
            }
        }).then(async resp => {
            const tokenAccess = resp.data.tokenAccess
            localStorage.setItem('tokenAccess', tokenAccess)

            if (resp.status === 200) {
                await this.getUser()
                history.push('/')
            }
        }).catch(function (error) {
            if (error.response && error.response.status === 400) {
                if (error.response.data.code === msg.emailNotValidated) {
                    swal({
                        title: msg.titleLoginError,
                        text: msg.emailNotValidatedMsg,
                        icon: 'warning'
                    })
                }
                else if (error.response.data.code === msg.incorrectEmailOrPw) {
                    swal({
                        title: msg.titleLoginError,
                        text: msg.emailOrPwError,
                        icon: 'error'
                    })                    
                }
            }
            else {
                swal({
                    title: msg.titleError,
                    text: msg.serverTrouble,
                    icon: 'error'
                })
            }
        })
    }

    async signup() {
        const passwordMatch = this.state.passwordMatch
        var checkbox = document.getElementById('terms-of-use-checkbox')

        const data = {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password,
            termsOfUseAccepted: checkbox.checked
        }

        if (passwordMatch === true) {
            await api.post('account', data, {
                headers: {
                    'Content-Type': headers.Content_Type
                }
            }).then(resp => {
                swal({
                    title: msg.titleSignup,
                    text: msg.confirmationEmailSentMsg,
                    icon: 'success'
                })
                .then(() => {
                    this.refreshPage()
                    this.setState({ loginMode: true })
                });
            }).catch(function (error) {
                if (error.response && error.response.status === 400) {
                    if (error.response.data.code === msg.emailAlreadyInUse) {

                        swal({
                            title: msg.titleSignupError,
                            text: msg.emailInUse,
                            icon: 'error'
                        })
                    }
                    else if (error.response.data.code === msg.couldNotSendEmail) {
                        swal({
                            title: msg.titleSignupError,
                            text: msg.couldNotSendEmailMsg,
                            icon: 'error'
                        })
                    }
                }
            })
        }
        else {
            swal({
                title: msg.titleSignupError,
                text: msg.passwordValidate,
                icon: 'error'
            })
        }
    }

    async getUser() {
        await api.get('account', {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Accept': headers.Accept,
                'Content-Type': headers.Content_Type
            }
        }).then(resp => {
            const firstName = resp.data.firstName
            const email = resp.data.email
            localStorage.setItem('firstName', JSON.stringify(firstName))
            localStorage.setItem('email', JSON.stringify(email))
        })
    }

    getQueryVariable(variable) {
        var query = this.props.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        return null;
    }

    isValidEmail(emailAddress) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(emailAddress).toLowerCase());
    }
    

    componentDidMount() {
        var registerFlag = this.getQueryVariable('register');
        var registerEmailAddress = this.getQueryVariable('registerEmailAddr');
        
        if(registerEmailAddress !== null && this.isValidEmail(registerEmailAddress)) {
            this.setState({
                email: registerEmailAddress
            });

            this.enableSignupMode();
        }
        else if(registerFlag === '1') {
            this.enableSignupMode();
        }
    }

    UNSAFE_componentWillMount() {
        const { history } = this.props
        var _this = this;
        if(this.props.match.path === '/verify/:token') {
            var token = this.props.match.params.token

            if(token && token.match(/^[0-9a-zA-Z]+$/)) {
                _this.setState({ loading: true })

                api.get('account/verify/' + token, {
                    headers: {
                        'Content-Type': headers.Content_Type
                    }
                }).then(resp => {
                    _this.setState({ loading: false })
                    swal({
                        title: msg.titleVerificationSuccess,
                        text: msg.verificationSuccess,
                        icon: 'success'
                    })
                }).catch(function (error) {
                    if (error.response && error.response.status === 400) {
                        swal({
                            title: msg.titleVerificationError,
                            text: msg.verificationError,
                            icon: 'error'
                        })
                        .then(() => {
                            history.push('/login')
                        })
                    }
                })
            }
        }
    }

    render() {
        const { loginMode } = this.state

        if(this.state.loading) {
            return <div />
        }

        return (
            <form id='login-form' onSubmit={this.handleSubmit}>
                <div className='wrapper fadeInDown'>
                    <div className='d-flex justify-content-center'>
                        <div id='formContent'>

                            {/* Tabs Titles */}

                            {/* Icon */}
                            <div className='fadeIn first' style={{padding: "20px"}}>
                                <img src={logo} className='logo' alt='' />
                            </div>

                            {/* Login Form */}
                            {/* <form> */}
                            <input type='input' className='fadeIn first'
                                name='firstName' placeholder='Primeiro Nome'
                                hidden={loginMode || this.state.recoverPasswordMode} onChange={this.handleChange} value={this.state.firstName} />

                            <input type='input' className='fadeIn first'
                                name='lastName' placeholder='Último Nome'
                                hidden={loginMode || this.state.recoverPasswordMode} onChange={this.handleChange} value={this.state.lastName} />

                            <input type='email' id='email'
                                className='fadeIn first' name='email'
                                placeholder='Email' onChange={this.handleChange} value={this.state.email} />

                            <input type='password' className='fadeIn second'
                                hidden={this.state.recoverPasswordMode} name='password' placeholder='Senha' onChange={this.handleChange} value={this.state.password} />

                            <input type='password' className='fadeIn second'
                                name='confirmPassword' placeholder='Confirmar Senha' onChange={this.handleConfirmPassword} hidden={loginMode || this.state.recoverPasswordMode} />

                            <div hidden={loginMode || this.state.recoverPasswordMode} className='fadeIn second' id='terms-of-use'>
                                <input id='terms-of-use-checkbox' onChange={this.handleTermsOfUse} name='terms-of-use' type='checkbox'/>
                                <p>Declaro que li e aceito os <a rel="noopener noreferrer" target='_blank' href={termsOfUsePDF}>Termos de Uso</a> do Codepeace.</p>
                            </div>

                            
                            <input disabled={!loginMode && !this.state.recoverPasswordMode && !this.state.termsOfUseAccepted} type='submit' className='fadeIn third' value={loginMode && !this.state.recoverPasswordMode ? 'Entrar' : this.state.recoverPasswordMode ? 'Recuperar conta': 'Registrar'} />
                            {/* </form> */}

                            <div id='formFooter'>
                                {/* <a class='underlineHover' href=''>Forgot Password?</a> */}
                                {
                                    this.state.loginMode ?
                                        <div>
                                            <a href="#0" onClick={() => this.enableRecoverPasswordMode()} className='underlineHover'>
                                                Esqueceu sua senha?
                                            </a><br/>
                                            <a href="#0" onClick={() => this.enableSignupMode()} className='underlineHover'>
                                                Novo usuário? Registrar aqui!
                                            </a>
                                        </div>
                                    :
                                    
                                    this.state.recoverPasswordMode?
                                        <div>
                                            <a href="#0" onClick={() => this.enableLoginMode()} className='underlineHover'>
                                                Já é cadastrado? Entrar aqui!
                                            </a><br/>
                                            <a href="#0" onClick={() => this.enableSignupMode()} className='underlineHover'>
                                                Novo usuário? Registrar aqui!
                                            </a>
                                        </div>
                                    :

                                    <div>
                                        <a href="#0" onClick={() => this.enableLoginMode()} className='underlineHover'>
                                            Já é cadastrado? Entrar aqui!
                                        </a><br/>
                                        <a href="#0" onClick={() => this.enableRecoverPasswordMode()} className='underlineHover'>
                                            Esqueceu sua senha?
                                        </a>
                                    </div>
                                        

                                }
                                </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default Auth