import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import api from '../services/api'
import headers from '../common/parameters/headers'
import Menu from './menu'
import txt from '../common/strings/appStrings'
import logo from '../assets/codepeace-logo.svg'

import './header.css'

class Header extends Component {

    state = {
        bits: ''
    }

    async componentDidMount() {
        await api.get('account/credits', {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Accept': headers.Accept,
                'Content-Type': headers.Content_Type
            }
        }).then(resp => {
            this.setState({ bits: resp.data.amount })
            localStorage.setItem('credits', resp.data.amount)
        })
    }

    render() {
        return (
            <header id='main-header'>
                <div className='header-content'>
                    <Link to='/' >
                        <img src={logo} className='logo' alt='' />
                    </Link>
                    <h5>{this.state.bits} {txt.credit}</h5>
                    <Menu />
                </div>
            </header>
        )
    }
}

export default Header
