export default {
    /** == main page */
    mainPageText: 'Você ainda não tem nenhum app para análise. Submeta um novo apk para receber sua análise de segurança.',
    
    newApplication: 'Nova Aplicação',
    headerTxt: 'CONTRATAR ANÁLISE DE SEGURANÇA DO APP:',
    bodyTxt: 'CONTRATAR ANÁLISE DE SEGURANÇA DO APP:',
    inputMonth: 'Mês',
    footerTxt: 'Total:',
    credit: 'Crédito(s)',

    /** == Ativar projeto */
    ActivationProject: 'Ativação de Projeto',
    activeHeader: 'ATIVAR O PROJETO:',
    activeBody: 'CONTRATAR ANÁLISE DE SEGURANÇA POR MAIS:',
    
    /** == Menu */
    myProjects: 'Meus Projetos',
    profile: 'Perfil',
    logout: 'Sair',
    billing: 'Créditos',
    coupon: 'Cupons',

    /** == project list */
    legendProjectList: 'Projetos',
    totalScans: 'Total de scans:',
    status: 'Status:',

    /** == detail page */
    legendProjectDetails: 'Análises',
    package: 'Pacote:',
    version: 'Versão:',
    scan_date: 'Data da análise:',
    exported_component: 'Componentes Exportados:',
    vulnerabilities: 'Vulnerabilidades:',
    
    uploaded: 'Na fila para análise',
    sent_to_analyser: 'Iniciando análise',
    running: 'Analisando',    
    failed: 'Falha ao analisar',
    take_while: 'Esta ação pode levar alguns minutos',
    analysis_error: 'Não foi possível analisar o aplicativo',
    generating_report: 'Gerando relatório',
    wait: 'Aguarde',
    loading: 'Carregando',


    /** == Perfil de usuário */
    userProfile: 'Perfil de Usuário',
    firstName: 'Primeiro Nome',
    lastName: 'Último Nome',
    email: 'E-mail de usuário',
    editPass: 'Alterar Senha',
    currentPass: 'Senha Atual',
    newPass: 'Nova Senha',
    confirmPass: 'Confirmar Senha',

    copyright: "Codepeace - Developed by SiDi",
    sidiUrl: "https://www.sidi.org.br",
    termsOfUseText: 'Termos de Uso',
}