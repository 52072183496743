import React, { Component } from 'react'
import swal from 'sweetalert'

import api from '../../services/api'
import headers from '../../common/parameters/headers'
import msg from '../../common/strings/messages'
import logo from '../../assets/codepeace-logo.svg'

import './recover.css'

class Recover extends Component {

    constructor(props) {
        super(props)

        this.state = {
            password: '',
            confirmPassword: '',
            passwordMatch: false,
            loading: false
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    changeMode() {
        this.setState({ loginMode: !this.state.loginMode })
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleConfirmPassword = e => {
        if (e.target.value === this.state.password) {
            this.setState({ passwordMatch: true })
        }
        else {
            this.setState({ passwordMatch: false })
        }
    }

    handleSubmit(values) {
        values.preventDefault()
        this.recoverAccount()
    }

    refreshPage = () => {
        window.location.reload(true)
    }

    async recoverAccount() {
        const { history } = this.props

        const passwordMatch = this.state.passwordMatch

        const data = {
            newPassword: this.state.password
        }

        var token = this.props.match.params.token

        if(token && token.match(/^[0-9a-zA-Z]+$/)) {
            if (passwordMatch === true) {
                await api.post('account/recover/' + token, data, {
                    headers: {
                        'Content-Type': headers.Content_Type
                    }
                }).then(resp => {
                    swal({
                        title: msg.titlePasswordChanged,
                        text: msg.passwordChanged,
                        icon: 'success'
                    })
                    .then(() => {
                        history.push('/login')
                    });
                }).catch(function (error) {
                    if (error.response && error.response.status === 400) {
                        swal({
                            title: msg.titlePasswordChangeError,
                            text: msg.passwordChangeError,
                            icon: 'error'
                        })
                        .then(() => {
                            history.push('/login')
                        });
                    }
                })
            }
            else {
                swal({
                    title: msg.titleSignupError,
                    text: msg.passwordValidate,
                    icon: 'error'
                })
            }
        }
    }

    UNSAFE_componentWillMount() {
        var _this = this;
        var token = this.props.match.params.token
        const { history } = this.props

        if(token && token.match(/^[0-9a-zA-Z]+$/)) {
            _this.setState({ loading: true })

            api.get('account/recover/' + token, {
                headers: {
                    'Content-Type': headers.Content_Type
                }
            }).then(resp => {
                _this.setState({ loading: false })
            }).catch(function (error) {
                if (error.response && error.response.status === 400) {
                    swal({
                        title: msg.titleRecoverLinkError,
                        text: msg.recoverLinkError,
                        icon: 'error'
                    }).then(() => {
                        history.push('/login')
                    })
                }
            })
        }
    }

    render() {
        const { loginMode } = this.state

        if(this.state.loading) {
            return <div />
        }

        return (
            <form id='login-form' onSubmit={this.handleSubmit}>
                <div className='wrapper fadeInDown'>
                    <div className='d-flex justify-content-center'>
                        <div id='formContent'>

                            {/* Tabs Titles */}

                            {/* Icon */}
                            <div className='fadeIn first' style={{padding: "20px"}}>
                                <img src={logo} className='logo' alt='' />
                            </div>

                            {/* Login Form */}
                            {/* <form> */}

                            <input type='password' className='fadeIn second'
                                name='password' placeholder='Senha' onChange={this.handleChange} value={this.state.password} />

                            <input type='password' className='fadeIn second'
                                name='confirmPassword' placeholder='Confirmar Senha' onChange={this.handleConfirmPassword} hidden={loginMode} />

                            <input type='submit' className='fadeIn third' value='Alterar Senha' />
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default Recover