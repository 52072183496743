export default {
    btnSubmitNewApk: 'SUBMETER NOVO APK',
    btnCreate:'Criar Aplicação',
    btnCancel:'Cancelar',
    btnCancelAccount:'Cancelar Conta',
    btnSave: 'Salvar',
    btnActivation: 'Ativar Projeto',

    btnActive: 'Ativar',
    btnDetails: 'DETALHES',
    btnLatestReport: 'BAIXAR',
    btnUploadApk: 'NOVA VERSÃO',
    btnDeleteProject: 'Deletar projeto',
    btnEditProject: 'Editar projeto',
    
    btnCompareProjects: 'COMPARAR',
    btnResults: 'RESULTADOS',

    btnConfirmDeleteProject: 'Deletar o projeto',
    btnConfirmUpdateAccount: 'Atualizar dados',
    btnConfirmLogout: 'Sair',
    btnDeleteAccount: 'Deletar conta',
}