import React, { Component } from 'react'
import swal from 'sweetalert'
import ReactLoading from 'react-loading'
import FileSaver from 'file-saver'

import api from '../../services/api'
import headers from '../../common/parameters/headers'
import msg from '../../common/strings/messages'
import btnStrings from '../../common/strings/btnStrings'
import txt from '../../common/strings/appStrings'
import Header from '../../components/header'
import Footer from '../../components/footer'
import more from '../../assets/more.svg'
import defaultIcon from '../../assets/app-default-icon.png'

import 'font-awesome/css/font-awesome.css'
import './projectList.css'

class PackageList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      apkFile: [],
      nameApk: '',
      projects: [],
      loading: false,
      loadButton: [],
      loadingReport: [],
      loadingActivate: false,
      month: 1,
      hideProjectList: false,
      hideCreateProject: true,
      hideActiveProject: true,
      projectID: '',
      prettyName: '',
      packageName: ''
    }

    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    this.search()
  }

  handleFileChange = e => {
    const nameApk = e.target.files[0].name
    this.setState({ hideProjectList: true, hideCreateProject: false, nameApk: nameApk, apkFile: e.target.files[0] })
  }

  handleSubmitNewApkClick = e => {
    var credits = localStorage.getItem('credits')

    if(credits && credits === '0') {
      e.preventDefault()
      swal({
        title: msg.titleErrorProject,
        text: msg.insufficientCreditsTitle,
        icon: 'error',
        buttons: {
          confirm: true
        }
      })
    }
  }

  htmlDecode(input) {
      var doc = new DOMParser().parseFromString(input, "text/html");

      if(!doc || !doc.documentElement) {
        return input;
      }

      return doc.documentElement.textContent;
  }

  handleNewFileChange = async (e, projectID) => {
    const { history } = this.props

    this.setState({ loadButton: this.state.loadButton.concat([projectID]) })

    const file = e.target.files[0]
    const data = new FormData()
    data.append('userfile', file)

    await api.post(`project/${projectID}/execution`, data, {
      headers: {
        'tokenAccess': localStorage.getItem('tokenAccess').toString(),
        'Accept': headers.Accept,
        'Content-Type': headers.Content_Type
      }
    }).then(resp => {
      swal({
        title: 'Nova versão submetida com sucesso!',
        text: 'Para visualizar os detalhes da análise, clique em "DETALHES"',
        icon: 'success'
      })
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (error.response.data.code === msg.invalidProjectID) {
          swal({
            title: msg.titleErrorNewVersion,
            text: msg.invalidProjectIDMsg,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.refreshPage()
          })
        }
        else if (error.response.data.code === msg.invalidFileFormat) {
          swal({
            title: msg.titleErrorNewVersion,
            text: msg.invalidFileFormatMsg,
            icon: 'error'
          })
        }
        else if (error.response.data.code === msg.invalidTokenAccess) {
          swal({
            title: msg.titleErrorNewVersion,
            text: msg.invalidToken,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.refreshPage()
          })
        }
        else if (error.response.data.code === msg.tokenAccessExpired) {
          swal({
            title: msg.titleErrorProject,
            text: msg.tokenExpired,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            history.push('/login')
          })
        }
      }
      else {
        swal({
          title: msg.titleErrorNewVersion,
          text: msg.serverTrouble,
          icon: 'error',
          buttons: {
            confirm: true
          }
        }).then(resp => {
          this.refreshPage()
        })
      }
    }).finally(() => {
      this.setState({ 
        loadButton: this.state.loadButton.filter((item) => item !== projectID) 
      })
    })
  }

  handleChange(event) {
    this.setState({ month: event.target.value })
  }

  handleClick(projectID, apkName, packageName, scans, queue) {
    const { history } = this.props

    localStorage.setItem('projectID', projectID)
    localStorage.setItem('apkName', apkName)
    localStorage.setItem('packageName', packageName)
    localStorage.setItem('scans', scans)
    localStorage.setItem('queue', queue)

    history.push({
      pathname: '/projectDetail',
    })
  }

  refreshPage = () => {
    window.location.reload(true)
  }

  createProject = async () => {
    this.setState({ loading: true })

    const { history } = this.props

    const file = this.state.apkFile
    const month = this.state.month

    const data = new FormData()
    data.append('userfile', file)

    await api.post('project', data, {
      headers: {
        'tokenAccess': localStorage.getItem('tokenAccess').toString(),
        'Month-Count': month,
        'Content-Type': headers.Content_Type
      }
    }).then(resp => {
      this.setState({ loading: false, hideProjectList: false, hideCreateProject: true })
      this.componentDidMount()
      swal({
        title: msg.titleSuccess,
        text: msg.uploadSuccess,
        icon: 'success'
      })
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (error.response.data.code === msg.duplicatedProjectName) {
          this.setState({ loading: false })
          swal({
            title: msg.titleErrorProject,
            text: msg.duplicatedName,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.refreshPage()
          })
        }
        else if (error.response.data.code === msg.tokenAccessExpired) {
          this.setState({ loading: false })
          swal({
            title: msg.titleErrorProject,
            text: msg.tokenExpired,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.setState({ hideProjectList: false, hideCreateProject: true })

            history.push('/login')
          })
        }
        else if (error.response.data.code === msg.invalidTokenAccess) {
          this.setState({ loading: false })
          swal({
            title: msg.titleErrorProject,
            text: msg.invalidToken,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.refreshPage()
          })
        }
        else if (error.response.data.code === msg.missingRequiredHeader) {
          this.setState({ loading: false })
          swal({
            title: msg.titleErrorProject,
            text: msg.missingHeader,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.refreshPage()
          })
        }
        else if (error.response.data.code === msg.missingTokenAccess) {
          this.setState({ loading: false })
          swal({
            title: msg.titleErrorProject,
            text: msg.missingToken,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.refreshPage()
          })
        }
        else if (error.response.data.code === msg.insufficientCredits) {
          this.setState({ loading: false })
          swal({
            title: msg.insufficientCreditsTitle,
            text: msg.insufficientCreditsMsg,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.refreshPage()
          })
        }
      }
      else {
        this.setState({ loading: false })
        this.setState({ hideProjectList: false })
        this.setState({ hideCreateProject: true })
        swal({
          title: msg.titleError,
          text: msg.serverTrouble,
          icon: 'error'
        })
      }
    })
  }

  updateAppIcon(projectID) {
    console.log('starting... ' + projectID)
    var iconStorageId = 'icon-' + projectID
    api.get('project/' + projectID + '/icon', {
      headers: {
        'tokenAccess': localStorage.getItem('tokenAccess').toString(),
        'Accept': headers.Accept,
        'Content-Type': headers.Content_Type
      }
    }).then(resp => {
      if(resp && resp.data && resp.data.iconData) {
        localStorage.setItem(iconStorageId, resp.data.iconData);
        this.forceUpdate()
      }
    })
  }

  async search() {
    const { history } = this.props

    await api.get('project', {
      headers: {
        'tokenAccess': localStorage.getItem('tokenAccess').toString(),
        'Accept': headers.Accept,
        'Content-Type': headers.Content_Type
      }
    }).then(resp => {
      const projects = resp.data.projects

      this.setState({ projects: projects })

      if (this.state.projects.length === 0) {
        this.setState({ showButton: false })

        swal({
          title: msg.titleListEmpty,
          text: msg.projectListEmpty,
          icon: 'warning',
          buttons: {
            confirm: true,
          }
        }).then(function () {
          history.push('/')
        })
      }
      else{
        for(var i = 0; i < projects.length; i++) {
          var projectID = projects[i].projectID;

          if(!projectID) {
            continue;
          }

          var iconStorageId = 'icon-' + projectID;

          if(localStorage.getItem(iconStorageId)) {
            continue;
          }
          
          this.updateAppIcon(projectID);
        }
      }
    }).catch(function (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.code === msg.invalidTokenAccess) {
          swal({
            title: msg.titleError,
            text: msg.invalidToken,
            icon: 'error',
            buttons: {
              confirm: true,
            }
          }).then(function () {
            history.push('/')
          })
        }
        else if (error.response.data.code === msg.tokenAccessExpired) {
          swal({
            title: msg.titleError,
            text: msg.tokenExpired,
            icon: 'error',
            buttons: {
              confirm: true,
            }
          }).then(function () {
            history.push('/login')
          })
        }
      }
      else {
        swal({
          title: msg.titleError,
          text: msg.serverTrouble,
          icon: 'error',
          buttons: {
            confirm: true,
          }
        }).then(function () {
          history.push('/login')
        })
      }
    })
  }

  updateProject(projectID, currentPrettyName) {
    const newNameInput = document.createElement('input')
    newNameInput.type = 'text'
    newNameInput.value = currentPrettyName
    newNameInput.setAttribute('class', 'swal-content__input')

    swal({
      title: msg.titleUpdateProjectName,
      content: newNameInput,
      buttons: {
        cancel: {
          text: btnStrings.btnCancel,
          value: false,
          visible: true,
          closeModal: true
        },
        confirm: {
          text: btnStrings.btnSave,
          visible: true,
          closeModal: true,
        }
      }
    })
    .then((willUpdate) => {
      if(willUpdate === false) {
        return
      }

      if(newNameInput.value == null || newNameInput.value === '') {
        swal({
          title: msg.emptyProjectNameNotAllowedTitle,
          text: msg.emptyProjectNameNotAllowedMessage,
          icon: 'warning',
          buttons: {
            confirm: true
          }
        })
        return
      }

      if(newNameInput.value.trim() === currentPrettyName) {
        swal({
          title: msg.noChangesDetectedTitle,
          text: msg.noChangesDetectedMessage,
          icon: 'warning',
          buttons: {
            confirm: true
          }
        })
        return;
      }

      const data = {
        "prettyName": newNameInput.value.trim()
      }
      
      api.put(`project/${projectID}`, data, {
        headers: {
          'tokenAccess': localStorage.getItem('tokenAccess').toString(),
          'Accept': headers.Accept,
          'Content-Type': headers.Content_Type
        }
      }).then(resp => {
        swal({
          title: msg.titleSuccess,
          text: msg.updateSuccess,
          icon: 'success',
          buttons: {
            confirm: true
          }
        }).then(resp => {
          this.componentDidMount()
        })
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          if (error.response.data.code === msg.invalidProjectID) {
            swal({
              title: msg.titleErrorUpdateProject,
              text: msg.invalidProjectIDMsg,
              icon: 'error'
            })
          }
          else if (error.response.data.code === msg.invalidTokenAccess) {
            swal({
              title: msg.titleErrorUpdateProject,
              text: msg.invalidToken,
              icon: 'error'
            })
          }
          else if (error.response.data.code === msg.tokenAccessExpired) {
            swal({
              title: msg.titleErrorUpdateProject,
              text: msg.tokenExpired,
              icon: 'error'
            })
          }
        }
        else {
          swal({
            title: msg.titleErrorUpdateProject,
            text: msg.serverTrouble,
            icon: 'error'
          })
        }
      })
    });
  }

  async delete(projectID) {
    const accountPassword = document.createElement('input')
    accountPassword.type = 'password'
    accountPassword.placeholder = 'Senha'
    accountPassword.setAttribute('class', 'swal-content__input')

    accountPassword.addEventListener('mouseover', function() {
      this.focus()
    })
    
    swal({
      title: msg.titleDeleteProjectConfirm,
      text:msg.deleteProjectConfirm,
      content: accountPassword,
      icon: 'warning',
      dangerMode: true,
      buttons: [btnStrings.btnCancel, btnStrings.btnConfirmDeleteProject],
    })
    .then((willDeleteProject) => {
      if(willDeleteProject) {
        if(accountPassword.value === null || accountPassword.value === ''){
          swal({
            title: msg.noPasswordProvidedTitle,
            text: msg.noPasswordProvidedMessage,
            icon: 'warning',
            buttons: {
              confirm: true
            }
          })
          return
        }

        var data = {
          'password': accountPassword.value
        }

        api.delete(`project/${projectID}`, {
          headers: {
            'tokenAccess': localStorage.getItem('tokenAccess').toString(),
            'Accept': headers.Accept,
            'Content-Type': headers.Content_Type
          },
          data: data
          
        }).then(resp => {
          swal({
            title: msg.titleSuccess,
            text: msg.deletSuccess,
            icon: 'success',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.componentDidMount()
          })
        }).catch(function(error) {
          if (error.response && error.response.status === 400) {
            if (error.response.data.code === msg.invalidProjectID) {
              swal({
                title: msg.titleErrorDeleteProject,
                text: msg.invalidProjectIDMsg,
                icon: 'error'
              })
            }
            else if (error.response.data.code === msg.invalidTokenAccess) {
              swal({
                title: msg.titleErrorDeleteProject,
                text: msg.invalidToken,
                icon: 'error'
              })
            }
            else if (error.response.data.code === msg.tokenAccessExpired) {
              swal({
                title: msg.titleErrorDeleteProject,
                text: msg.tokenExpired,
                icon: 'error'
              })
            }
            else if (error.response.data.code === msg.incorrectEmailOrPw) {
              swal({
                  title: msg.titleErrorDeleteProject,
                  text: msg.emailOrPwError,
                  icon: 'error',
                  buttons: {
                      confirm: true
                  }
              })
            }
          }
          else {
            swal({
              title: msg.titleErrorDeleteProject,
              text: msg.serverTrouble,
              icon: 'error'
            })
          }
        })
      }
    });
  }

  async getLatestReport(lastFinishedExecutionId, prettyName) {
    this.setState({
      loadingReport: this.state.loadingReport.concat([lastFinishedExecutionId])
    })

    const type = 'pdf'

    const dt = Date.now()
    const timestamp = new Intl.DateTimeFormat('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(dt)

    await api.get(`report/${lastFinishedExecutionId}/${type}`, {
      headers: {
        'tokenAccess': localStorage.getItem('tokenAccess').toString(),
        'Content-Type': headers.Content_Type
      },
      responseType: 'blob'
    }).then(resp => {
      const file = new File([resp.data], `${prettyName} - ${timestamp}.${type}`)
      FileSaver.saveAs(file)
    }).finally(() => {
      this.setState({
        loadingReport: this.state.loadingReport.filter((item) => item !== lastFinishedExecutionId)
      })
    })
  }

  activate(projectID, prettyName, packageName) {
    var credits = localStorage.getItem('credits')

    if(credits && credits === '0') {
      swal({
        title: msg.titleErrorActivate,
        text: msg.insufficientCreditsTitle,
        icon: 'error',
        buttons: {
          confirm: true
        }
      })
    }
    else{
      this.setState({
        projectID: projectID, prettyName: prettyName, hideProjectList: true, hideCreateProject: true,
        hideActiveProject: false, packageName: packageName
      })
    }
    
  }

  activateProject = async () => {
    const { history } = this.props

    const projectId = this.state.projectID
    var month = this.state.month
    
    if(typeof month === 'string') {
      month = parseInt(month)
    }

    const data = {
      "monthCount": month
    }

    this.setState({ loadingActivate: true })

    await api.post(`project/${projectId}/activate`, data, {
      headers: {
        'tokenAccess': localStorage.getItem('tokenAccess').toString(),
        'Accept': headers.Accept,
        'Content-Type': headers.Content_Type,
      }
    }).then(resp => {
      this.setState({
        loadingActivate: false, hideProjectList: false, hideCreateProject: true,
        hideActiveProject: true
      })

      this.componentDidMount()

      swal({
        title: msg.titleProjectActivated,
        text: msg.projectActivatedMsg,
        icon: 'success'
      }).then(() => {
        this.refreshPage()
      })
    }).catch(error => {
      if (error.response && error.response.status === 400) {
        if (error.response.data.code === msg.invalidProjectID) {
          this.setState({ loadingActivate: false })
          swal({
            title: msg.titleErrorActivate,
            text: msg.invalidProjectIDMsg,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.refreshPage()
          })
        }
        else if (error.response.data.code === msg.invalidTokenAccess) {
          this.setState({ loadingActivate: false })
          swal({
            title: msg.titleErrorActivate,
            text: msg.invalidToken,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.refreshPage()
          })
        }
        else if (error.response.data.code === msg.tokenAccessExpired) {
          this.setState({ loadingActivate: false })
          swal({
            title: msg.titleErrorActivate,
            text: msg.tokenExpired,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.setState({
              hideProjectList: true, hideCreateProject: true,
              hideActiveProject: false
            })

            history.push('/login')
          })
        }
        else if (error.response.data.code === msg.projectAlreadyActive) {
          this.setState({ loadingActivate: false })
          swal({
            title: msg.titleErrorActivate,
            text: msg.projectAlreadyActiveMsg,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.setState({
              hideProjectList: false, hideCreateProject: true,
              hideActiveProject: true
            })
          })
        }
        else if (error.response.data.code === msg.invalidCreditAmount) {
          this.setState({ loadingActivate: false })
          swal({
            title: msg.titleErrorActivate,
            text: msg.invalidCreditAmountMsg,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.setState({
              hideProjectList: true, hideCreateProject: true,
              hideActiveProject: false
            })
          })
        }
        else if (error.response.data.code === msg.insufficientCredits) {
          this.setState({ loadingActivate: false })
          swal({
            title: msg.titleErrorActivate,
            text: msg.insufficientCreditsTitle,
            icon: 'error',
            buttons: {
              confirm: true
            }
          }).then(resp => {
            this.setState({
              hideProjectList: false, hideCreateProject: true,
              hideActiveProject: true
            })
          })
        }
      }
    })
  }

  render() {
    const { loading } = this.state
    const { loadButton } = this.state
    const { loadingActivate } = this.state

    return (
      <div id='div-projects-list'>
        <Header />
        <div id='body'>
          <div hidden={this.state.hideProjectList}>
            <label className='submit-apk btn btn-default'>
              <input type='file' name='file' onClick={this.handleSubmitNewApkClick} onChange={this.handleFileChange} />
              {btnStrings.btnSubmitNewApk}&nbsp;&nbsp;<i className='fa fa-plus'></i>
            </label>

            <legend className='legend'>{txt.legendProjectList}</legend>

            <section id='packages-list'>
              {this.state.projects.map(list => (
                <article key={list.projectID}>
                  <header>
                    <div>
                      <div className='inline-block-child'>
                        {localStorage.getItem('icon-' + list.projectID) ? 
                          <img className='app-icon' alt='app-icon' src={`data:image/png;base64, ${localStorage.getItem('icon-' + list.projectID)}`} width="96px" height="96px" />
                          :
                          <img className='app-icon' alt='app-icon' src={defaultIcon} width="96px" height="96px" />
                        }
                      </div>
                      <div className='packages-header inline-block-child'>
                        <span className='package-prettyname'>{this.htmlDecode(list.prettyName)}</span>
                        <span className='sub-info'>{list.packageName}</span>
                      </div>
                    </div>
                    

                    <div className='btn-group dropdown-btn-group'>

                      <div className='dropdown-toggle packages-img project-options-menu' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                        <img alt='app-icon' src={more}/>
                      </div>
                      

                      <div className='dropdown-menu dropdown-menu project-menu'>
                      <span className='dropdown-item item' onClick={() => this.updateProject(list.projectID, this.htmlDecode(list.prettyName))}>
                          {btnStrings.btnEditProject}
                        </span>
                        <div className="dropdown-divider"></div>
                        <span className='dropdown-item item' onClick={() => this.delete(list.projectID)}>
                          {btnStrings.btnDeleteProject}
                        </span>
                      </div>
                    </div>
                  </header>

                  {list.is_active === true ?
                    <div className='packages-body'>
                      <span className='caption'><strong>{txt.totalScans}</strong> {list.totalScans}</span>
                      <span className='caption'><strong>{txt.status}</strong> {list.queueCount} na fila</span>
                      <span className='caption expires'>Expira em {list.last_expiration_date.split(' ')[0]}</span>
                    </div>
                    :
                    <div className='packages-body'>
                      <span className='caption'><strong>{txt.totalScans}</strong> {list.totalScans}</span>
                      <span className='caption'><strong>{txt.status}</strong> {list.queueCount} na fila</span>
                      <span className='caption expired'><strong>Este projeto expirou em {list.last_expiration_date.split(' ')[0]}</strong></span>
                    </div>
                  }

                  <footer>
                    <div key={list.projectID} className='row'>
                      <div className='col-sm-12 text-right'>

                        {list.is_active === true ?
                            <span>
                            {loadButton.includes(list.projectID) ? 
                              <button disabled='disabled' className='btn-activate bt'><span className='bt'><i className='fa fa-refresh fa-spin'> </i> {txt.loading}</span></button>

                              :

                              <label title='Enviar APK deste projeto para análise' className='btn-activate bt'
                                onChange={(e) => this.handleNewFileChange(e, list.projectID)}>
                                  <i className='fa fa-upload'> <span className='bt'>{btnStrings.btnUploadApk}</span></i>
                                <input type='file' name='file' />
                              </label> 
                            }
                            </span>
                          
                          
                          :
                          
                          <button onClick={() => this.activate(list.projectID, this.htmlDecode(list.prettyName), list.packageName)}
                            type='button' className='btn-activate bt' title='Ativar projeto'>
                            <i className='fa fa-magic'></i>&nbsp;&nbsp;{btnStrings.btnActive}
                          </button>
                        }

                        <button title='Baixar relatório da última análise' disabled={list.lastFinishedExecutionId == null || list.lastFinishedExecutionId === "" || this.state.loadingReport.includes(list.lastFinishedExecutionId)} onClick={() => this.getLatestReport(list.lastFinishedExecutionId, this.htmlDecode(list.prettyName))}
                          type='button' className='btn-activate bt'>
                            {this.state.loadingReport.includes(list.lastFinishedExecutionId) ?
                                <div><i className='fa fa-refresh fa-spin'></i>&nbsp;&nbsp;<span className='bt'>{txt.wait}</span></div>
                              : 
                                <div><i className='fa fa-download'></i>&nbsp;&nbsp;<span className='bt'>{btnStrings.btnLatestReport}</span></div>
                            }    
                        </button>
                        <button
                          onClick={this.handleClick.bind(this, list.projectID, this.htmlDecode(list.prettyName), list.packageName, list.totalScans, list.queueCount)}
                          title='Detalhes' className='btn btn-dark details bt'><i style={{fontSize: '20px'}} className='fa fa-file-text-o'></i>
                        </button>
                      </div>
                    </div>
                  </footer>
                </article>
              ))}
            </section>
          </div>

          {loading ? <div className='progress'><ReactLoading type={'bubbles'} color={'#43B6AE'} /></div> :
            <div id='new-project-container' hidden={this.state.hideCreateProject}>
                <div className='new-project'>
                  <div>
                    <span id='title'>
                      CONTRATAR ANÁLISE DE SEGURANÇA:
                    </span>

                    <section className='newproject-card-header'>
                        <div id='app-icon'>
                            <img alt='app-icon'  src={defaultIcon} width="96px" height="96px" />
                        </div>
                        <div id='app-info'>
                          <span id='label'>Novo App</span>
                          <span id='apk-name'>{this.state.nameApk}</span>
                        </div>
                    </section>

                    <hr />

                    <div className='newproject-card-body'>
                      <div id='label'>
                        <span>
                          LIBERAR ANÁLISES ILIMITADAS POR:
                        </span>
                      </div>
                      
                      <div id='month-input'>
                        <input id='input' name='month' type='number' min='1' value={this.state.month}
                            onChange={this.handleChange} /><span id='label'>Meses</span>
                      </div>

                      <div id='label-limit'>
                        <div className='label-text'>
                          LIMITE DE ANÁLISES:
                        </div>
                        <div className='label-value'>
                        ∞
                        </div>
                      </div>

                    </div>
                    
                    <hr />

                    <div className='newproject-card-footer'>
                      <div id='label-total'>
                        <div className='label-text'>
                          TOTAL:
                        </div>
                        <div className='label-value'>
                          {this.state.month} CRÉDITO(S)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='new-project-buttons'>
                    <button className='btn cancel-btn' onClick={this.refreshPage}>{btnStrings.btnCancel}</button>
                    <button className='submit-apk btn btn-default' onClick={this.createProject}>{btnStrings.btnCreate}</button>
                </div>
            </div>
          }


          {loadingActivate ? <div className='progress'><ReactLoading type={'bubbles'} color={'#43B6AE'} /></div> :
            <div id='activate-project-container' hidden={this.state.hideActiveProject}>
              <div className='new-project'>
                  <div>
                    <span id='title'>
                      ATIVAR ANÁLISE DE SEGURANÇA PARA O APP:
                    </span>

                    <section className='newproject-card-header'>
                        <div id='app-icon'>
                            <img style={localStorage.getItem('icon-'+this.state.projectID) ? {border: 'none'} : {border: '1px solid'} } alt='app-icon'  src={localStorage.getItem('icon-'+this.state.projectID) ? `data:image/png;base64, ${localStorage.getItem('icon-' + this.state.projectID)}` :  defaultIcon} width="96px" height="96px" />
                        </div>
                        <div id='app-info'>
                          <span id='label'>{this.htmlDecode(this.state.prettyName)}</span>
                          <span id='apk-name'>{this.state.packageName}</span>
                        </div>
                    </section>

                    <hr />

                    <div className='newproject-card-body'>
                      <div id='label'>
                        <span>
                          LIBERAR ANÁLISES ILIMITADAS POR:
                        </span>
                      </div>
                      
                      <div id='month-input'>
                        <input id='input' name='month' type='number' min='1' value={this.state.month}
                            onChange={this.handleChange} /><span id='label'>Meses</span>
                      </div>

                      <div id='label-limit'>
                        <div className='label-text'>
                          LIMITE DE ANÁLISES:
                        </div>
                        <div className='label-value'>
                        ∞
                        </div>
                      </div>

                    </div>
                    
                    <hr />

                    <div className='newproject-card-footer'>
                      <div id='label-total'>
                        <div className='label-text'>
                          TOTAL:
                        </div>
                        <div className='label-value'>
                          {this.state.month} CRÉDITO(S)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='new-project-buttons'>
                    <button className='btn cancel-btn' onClick={this.refreshPage}>{btnStrings.btnCancel}</button>
                    <button className='submit-apk btn btn-default' onClick={this.activateProject}>{btnStrings.btnActivation}</button>
                </div>
              
            </div>
          }
          
         </div>
        <Footer />
      </div>
    )
  }
}

export default PackageList

