export default {
    titleError: 'Erro ao carregar as informações',

    // Erros relacionados ao token/servidor
    tokenAccessExpired: 'tokenAccessExpired',
    tokenExpired: 'Token de acesso expirou!',

    invalidTokenAccess: 'invalidTokenAccess',
    invalidToken: 'Token de acesso inválido',

    serverTrouble: 'Internal server error',

    titleSuccess: 'Operação realizada com sucesso!',

    // Messagens das rotas de upload new apk
    titleErrorProject: 'Erro ao criar o projeto',

    projectCreated: 'projectCreated',
    uploadSuccess: 'Projeto foi criado com sucesso!',

    duplicatedProjectName: 'duplicatedPackageName',
    duplicatedName: 'Nome projeto já está sendo usado pelo usuário',

    missingRequiredHeader: 'missingRequiredHeader',
    missingHeader: 'Um ou mais dos cabeçalhos necessários estão ausentes',

    missingTokenAccess: 'missingTokenAccess',
    missingToken: 'Token de acesso está perdido',

    insufficientCredits: 'insufficientCredits',
    insufficientCreditsTitle: 'Créditos insuficientes para executar esta ação',
    insufficientCreditsMsg: 'Adquira créditos para contratar análise para o seu apk!',

    // Upload new version
    titleErrorNewVersion: 'Erro ao submeter nova versão',

    invalidProjectID: 'invalidProjectID',
    invalidProjectIDMsg: 'Id do projeto inválido',

    invalidFileFormat: 'invalidFileFormat',
    invalidFileFormatMsg: 'Formato do aqruivo inválido. Formato suportado .apk',

    // Ativar projeto
    titleErrorActivate: 'Erro na ativação do projeto.',
    titleProjectActivated: 'Projeto ativado.',

    projectActivated: 'projectActivated',
    projectActivatedMsg: 'Ativação do projeto com sucesso!',

    projectAlreadyActive: 'projectAlreadyActive',
    projectAlreadyActiveMsg: 'O projeto já está ativo.',

    invalidCreditAmount: 'invalidCreditAmount',
    invalidCreditAmountMsg: 'O valor do crédito fornecido tem um tipo errado ou é inválido.',

    // Erros na pagina de project list
    titleListEmpty: 'Você ainda não possui projetos cadastrados.',
    projectListEmpty: 'Submeta o seu apk para cadastrar um novo projeto!',

    // Delete mensagens
    deletSuccess: 'Projeto foi deletado com sucesso!',
    titleErrorDeleteProject: 'Erro ao deletar projeto',

    // Update mensagens
    updateSuccess: 'Projeto foi atualizado com sucesso!',
    titleErrorUpdateProject: 'Erro ao atualizar projeto',

    // Erro verificação token
    titleVerificationError: 'Erro ao verificar sua conta',
    verificationError: 'O link de verificação é inválido ou expirou.',

    // Link de recuperação inválido
    titleRecoverLinkError: 'Link de recuperação inválido',
    recoverLinkError: 'O link de recuperação é invalido ou expirou',

    // Email de recuperação enviado
    titleRecoverEmailSent: 'Email enviado',
    recoverEmailSent: 'Um email de recuperação de senha foi enviado',

    // Email de recuperação não enviado
    titleRecoveryAttemptFailed: 'Erro ao recuperar a conta',
    recoveryAttemptFailed: 'Ocorreu um erro ao tentar recuperar sua conta',

    // Sucesso na recuperação de conta
    titlePasswordChanged: 'Senha alterada',
    passwordChanged: 'Sua senha foi alterada com sucesso',

    // Falha na recuperação de conta
    titlePasswordChangeError: 'Falha ao alterar a senha',
    passwordChangeError: 'Ocorreu um erro ao alterar a senha',

    // Sucesso verificação token
    titleVerificationSuccess: 'Conta verificada',
    verificationSuccess: 'A conta foi verificada com sucesso',

    // Erro do login
    titleLoginError: 'Erro ao tentar logar',
    incorrectEmailOrPw: 'incorrectEmailOrPw',
    emailOrPwError: 'Email/Senha estão inválidos',

    // Erro do cadastro de usuário
    titleSignup: 'Cadastro realizado com sucesso.',
    titleSignupError: 'Erro ao tentar se cadastrar',

    confirmationEmailSent: 'confirmationEmailSent',
    confirmationEmailSentMsg: 'Email de confirmação foi enviado',

    emailAlreadyInUse: 'emailAlreadyInUse',
    emailInUse: 'Email já está sendo usado',

    couldNotSendEmail:'couldNotSendEmail',
    couldNotSendEmailMsg: 'Algo saiu errado e o email para verificação não pode ser enviado.',

    passwordValidate: 'As senhas não conferem.',

    // Encerrar sessão logada
    sessionEnded: 'sessionEnded',
    titleLogoutSucess: 'Sessão encerrada com sucesso!',
    logoutSucess: 'Logout feito com sucesso',

    // Validação de email
    emailNotValidated: 'emailNotValidated',
    emailNotValidatedMsg: 'Aguardando confirmação de email',

    // Atualizar usuário
    titleUpdateUserError: 'Erro ao alterar o usuário',
    titleUpdateUser: 'Usuário alterado com sucesso.',

    userFieldsUpdatedAndConfirmationEmailSent: 'userFieldsUpdatedAndConfirmationEmailSent',
    userFieldsUpdatedAndConfirmationEmailSentMsg: 'O email de confirmação foi enviado e os campos foram alterados com sucesso.',

    userFieldsUpdated: 'userFieldsUpdated',
    userFieldsUpdatedMsg: 'Os campos de usuário foi alterado.',

    invalidPassword: 'invalidPassword',
    invalidPasswordMsg: 'Senha atual inválida',

    // Deletar conta
    titleDeleteAccount: 'Conta cancelada',
    deleteAccount: 'A sua conta foi cancelada com sucesso.',

    // Pagamento realizado com sucesso
    titlePaypalPaymentSuccess: 'Sucesso',
    paypalPaymentSuccess: 'O pagamento foi processado com sucesso',

    titlePaypalPaymentError: 'Erro ao processar pagamento',
    paypalPaymentError: 'Ocorreu um erro ao processar o pagamento',

    titleDeleteProjectConfirm: 'Deletar projeto?',
    deleteProjectConfirm: 'Atenção! Esta ação não pode ser desfeita. Para continuar você deve digitar sua senha abaixo.',

    titleLogoutConfirm: 'Deseja sair?',
    logoutConfirm: 'Você tem certeza que deseja sair da sua conta (logout)?',

    titleCancelAccountConfirm: 'Deletar sua conta?',
    cancelAccountConfirm: 'Atenção! Esta ação apagará todos os dados da sua conta (incluindo relatórios) de maneira irrecuperável. Para continuar você precisa digitar sua senha abaixo.',

    titleUpdateProjectName: 'Atualizar nome do projeto',

    titleUpdateAccountInfoConfirm: 'Atualizar dados da conta',
    updateAccountInfoConfirm: 'Para atualizar os dados de sua conta você deve digitar a senha',

    noPasswordProvidedTitle: 'Senha inválida',
    noPasswordProvidedMessage: 'Nenhuma senha foi fornecida',

    noChangesDetectedTitle: 'Nenhuma mudança detectada',
    noChangesDetectedMessage: 'Você não realizou nenhuma mudança no nome do projeto. Portanto nenhum dado foi atualizado.',

    emptyProjectNameNotAllowedTitle: 'Nenhum nome especificado',
    emptyProjectNameNotAllowedMessage: 'O nome do projeto não pode ser vazio.',
    
    titleInvalidCoupon: 'Cupom inválido',
    invalidCoupon: 'O cupom fornecido é inválido ou expirou',

    titleCouponAlreadyTaken: 'Cupom já resgatado',
    couponAlreadyTaken: 'O cupom fornecido já foi resgatado',

    titleCouponRedeemSuccess: 'Cupom resgatado',
    couponRedeemSuccessPart1: 'O cupom foi resgatado com sucesso! O valor de ',
    couponRedeemSuccessPart2: ' crédito(s) foi adicionado à sua conta.',
    couponRedeemSuccessGeneric: 'O cupom foi resgatado com sucesso!',

    // Erro desconhecido
    titleUnknownError: 'Erro inesperado',
    unknownError: 'Um erro inesperado aconteceu'
}