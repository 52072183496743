import React, { Component } from 'react'
import swal from 'sweetalert'
import ReactLoading from 'react-loading'

import api from '../../services/api'
import headers from '../../common/parameters/headers'
import txt from '../../common/strings/appStrings'
import btnStrings from '../../common/strings/btnStrings'
import msg from '../../common/strings/messages'
import Header from '../../components/header'
import Footer from '../../components/footer'
import defaultIcon from '../../assets/app-default-icon.png'

import './mainPage.css'

class MainPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            apkFile: [],
            nameApk: '',
            loading: false,
            month: '1',
            hideMainPage: false,
            hideCreateProject: true
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.search()
    }

    handleSubmitNewApkClick = e => {
        var credits = localStorage.getItem('credits')
    
        if(credits && credits === '0') {
          e.preventDefault()
          swal({
            title: msg.titleErrorProject,
            text: msg.insufficientCreditsTitle,
            icon: 'error',
            buttons: {
              confirm: true
            }
          })
        }
      }

    async search() {
        const { history } = this.props
        this.setState({ hideMainPage: true })

        await api.get('project', {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Accept': headers.Accept,
                'Content-Type': headers.Content_Type
            }
        }).then(resp => {
            const projects = resp.data.projects

            this.setState({ projects: projects })

            if (this.state.projects.length !== 0) {
                history.push('/projectList')
            }
            else{
                this.setState({ hideMainPage: false })
            }
        }).catch(function (error) {
            this.setState({ hideMainPage: false })
            if (error.response && error.response.status === 400) {
                if (error.response.data.code === msg.invalidTokenAccess) {
                    swal({
                        title: msg.titleError,
                        text: msg.invalidToken,
                        icon: 'error',
                        buttons: {
                            confirm: true,
                        }
                    }).then(function () {
                        history.push('/')
                    })
                }
                else if (error.response.data.code === msg.tokenAccessExpired) {
                    swal({
                        title: msg.titleError,
                        text: msg.tokenExpired,
                        icon: 'error',
                        buttons: {
                            confirm: true,
                        }
                    }).then(function () {
                        history.push('/login')
                    })
                }
            }
            else {
                swal({
                    title: msg.titleError,
                    text: msg.serverTrouble,
                    icon: 'error',
                    buttons: {
                        confirm: true,
                    }
                }).then(function () {
                    history.push('/login')
                })
            }
        })
    }

    handleFileChange = e => {
        const nameApk = e.target.files[0].name
        this.setState({ hideMainPage: true, hideCreateProject: false, nameApk: nameApk, apkFile: e.target.files[0] })
    }

    handleChange(event) {
        this.setState({ month: event.target.value })
    }

    refreshPage = () => {
        window.location.reload(true)
    }

    createProject = async () => {
        this.setState({ loading: true })

        const { history } = this.props

        const file = this.state.apkFile
        const month = this.state.month

        const data = new FormData()
        data.append('userfile', file)

        await api.post('project', data, {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Month-Count': month,
                'Content-Type': headers.Content_Type
            }
        }).then(resp => {
            this.setState({ loading: false })

            swal({
                title: msg.titleSuccess,
                text: msg.uploadSuccess,
                icon: 'success',
                buttons: {
                    confirm: true
                }
            }).then(function () {
                history.push('/projectList')
            })
        }).catch(error => {
            if (error.response && error.response.status === 400) {
                if (error.response.data.code === msg.duplicatedProjectName) {
                    this.setState({ loading: false })
                    swal({
                        title: msg.titleErrorProject,
                        text: msg.duplicatedName,
                        icon: 'error',
                        buttons: {
                            confirm: true
                        }
                    }).then(resp => {
                        this.refreshPage()
                    })
                }
                else if (error.response.data.code === msg.tokenAccessExpired) {
                    this.setState({ loading: false })
                    swal({
                        title: msg.titleErrorProject,
                        text: msg.tokenExpired,
                        icon: 'error',
                        buttons: {
                            confirm: true
                        }
                    }).then(resp => {
                        this.setState({ hideMainPage: false, hideCreateProject: true })

                        history.push('/login')
                    })
                }
                else if (error.response.data.code === msg.invalidTokenAccess) {
                    this.setState({ loading: false })
                    swal({
                        title: msg.titleErrorProject,
                        text: msg.invalidToken,
                        icon: 'error',
                        buttons: {
                            confirm: true
                        }
                    }).then(resp => {
                        this.refreshPage()
                    })
                }
                else if (error.response.data.code === msg.missingRequiredHeader) {
                    this.setState({ loading: false })
                    swal({
                        title: msg.titleErrorProject,
                        text: msg.missingHeader,
                        icon: 'error',
                        buttons: {
                            confirm: true
                        }
                    }).then(resp => {
                        this.refreshPage()
                    })
                }
                else if (error.response.data.code === msg.missingTokenAccess) {
                    this.setState({ loading: false })
                    swal({
                        title: msg.titleErrorProject,
                        text: msg.missingToken,
                        icon: 'error',
                        buttons: {
                            confirm: true
                        }
                    }).then(resp => {
                        this.refreshPage()
                    })
                }
                else if (error.response.data.code === msg.insufficientCredits) {
                    this.setState({ loading: false })
                    swal({
                        title: msg.insufficientCreditsTitle,
                        text: msg.insufficientCreditsMsg,
                        icon: 'error',
                        buttons: {
                            confirm: true
                        }
                    }).then(resp => {
                        this.refreshPage()
                    })
                }
            }
            else {
                this.setState({ loading: false, hideProjectList: false, hideCreateProject: true })
                swal({
                    title: msg.titleError,
                    text: msg.serverTrouble,
                    icon: 'error'
                })
            }
        })
    }

    render() {
        const { loading } = this.state

        return (
            <div id='div-main'>
                <Header />
                <div id='body'>
                    <section className='empty-new-project' id='submit-apk' hidden={this.state.hideMainPage}>
                        <article>
                            <div className='submit-file-page'>
                                <p className='subtitle1'>
                                    {txt.mainPageText}
                                </p>
                            </div>

                            <div className='submit-file-page'>
                                <label className='submit-apk btn btn-default btn-lg' onChange={this.handleFileChange}>
                                    <input type='file' name='file' onClick={this.handleSubmitNewApkClick} />
                                    {btnStrings.btnSubmitNewApk} <i className='fa fa-plus'></i>
                                </label>
                            </div>
                        </article>
                    </section>

                    {loading ? <div className='progress'><ReactLoading type={'bubbles'} color={'#43B6AE'} /></div> :
                        <div id='new-project-container' hidden={this.state.hideCreateProject}>
                            <div className='new-project'>
                            <div>
                                <span id='title'>
                                CONTRATAR ANÁLISE DE SEGURANÇA:
                                </span>
            
                                <section className='newproject-card-header'>
                                    <div id='app-icon'>
                                        <img alt='app-icon'  src={defaultIcon} width="96px" height="96px" />
                                    </div>
                                    <div id='app-info'>
                                    <span id='label'>Novo App</span>
                                    <span id='apk-name'>{this.state.nameApk}</span>
                                    </div>
                                </section>
            
                                <hr />
            
                                <div className='newproject-card-body'>
                                <div id='label'>
                                    <span>
                                    LIBERAR ANÁLISES ILIMITADAS POR:
                                    </span>
                                </div>
                                
                                <div id='month-input'>
                                    <input id='input' name='month' type='number' min='1' value={this.state.month}
                                        onChange={this.handleChange} /><span id='label'>Meses</span>
                                </div>
            
                                <div id='label-limit'>
                                    <div className='label-text'>
                                    LIMITE DE ANÁLISES:
                                    </div>
                                    <div className='label-value'>
                                    ∞
                                    </div>
                                </div>
            
                                </div>
                                
                                <hr />
            
                                <div className='newproject-card-footer'>
                                <div id='label-total'>
                                    <div className='label-text'>
                                    TOTAL:
                                    </div>
                                    <div className='label-value'>
                                    {this.state.month} CRÉDITO(S)
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className='new-project-buttons'>
                                <button className='btn cancel-btn' onClick={this.refreshPage}>{btnStrings.btnCancel}</button>
                                <button className='submit-apk btn btn-default' onClick={this.createProject}>{btnStrings.btnCreate}</button>
                            </div>
                        </div>
                    }
                    
                </div>
                <Footer />
                
            </div>
        )
    }
}

export default MainPage