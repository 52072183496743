import React, { Component } from 'react'
import txt from '../common/strings/appStrings'
import termsOfUse from '../assets/terms-of-use.pdf'

import './footer.css'

class Footer extends Component {

    render() {
        return (
            <div id='main-footer'>
                <hr />
                <footer>
                    
                    <div className='footer-content'>
                        <div></div>
                        <div>
                            <p>{txt.copyright} - <a href={txt.sidiUrl}>{txt.sidiUrl}</a></p>
                            <p> <a rel="noopener noreferrer" target='_blank' href={termsOfUse}>{txt.termsOfUseText}</a> - <a href='mailto:contato@codepeaceapp.io'>Entre em Contato</a></p>
                        </div>
                        <div></div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Footer
