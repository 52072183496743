import React, { Component } from 'react'

import './notFound.css'

class NotFound extends Component {
    constructor(props) {
        super(props)

        this.props.history.push('/login')
    }

    render() {
        return (
            <div className='container'>
                <h1 className='notfound'>Not found</h1>
            </div>
        )
    }
}

export default NotFound