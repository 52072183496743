import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'

import api from '../services/api'
import headers from '../common/parameters/headers'
import msg from '../common/strings/messages'
import btnStrings from '../common/strings/btnStrings'
import txt from '../common/strings/appStrings'
import menu from '../assets/icon-menu.svg'

import './menu.css'

class Navbar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: JSON.parse(localStorage.getItem('firstName')),
            email: JSON.parse(localStorage.getItem('email')),
        }
        this.handleLogout = this.handleLogout.bind(this)
    }

    handleLogout(e) {
        e.preventDefault()

        swal({
            title: msg.titleLogoutConfirm,
            text:msg.logoutConfirm,
            icon: 'warning',
            dangerMode: true,
            buttons: [btnStrings.btnCancel, btnStrings.btnConfirmLogout]
        })
        .then((willLogout) => {
            if(willLogout) {
                this.logout()
            }
        })
    }

    async logout() {
        await api.get('account/logout', {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Accept': headers.Accept,
                'Content-Type': headers.Content_Type
            }
        }).then(resp => {
            if (resp.data.code === msg.sessionEnded) {
                localStorage.clear()
                window.location.href = '/login'
            }
        })
    }

    render() {
        return (
            <div className='btn-group'>
                <div className='menu'>
                    <span>{this.state.name}</span>
                </div>

            <img className='dropdown-toggle user'
                    src={menu} alt='' title='Edit Profile'
                    data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' />

                <div className='dropdown-menu dropdown-menu-right user-menu'>
                    <div className='user-menu-header'>
                        <p>
                            {this.state.name}<br/>
                            <small>{this.state.email}</small>
                        </p>
                    </div>

                    <div className='dropdown-divider'></div>

                    <Link to='/projectlist' className='dropdown-item item' >{txt.myProjects}</Link>
                    <Link to='/profilesettings' className='dropdown-item item' >{txt.profile}</Link>
                    <Link to='/billing' className='dropdown-item item' >{txt.billing}</Link>

                    <div className='dropdown-divider'></div>

                    <span onClick={this.handleLogout} className='dropdown-item item' >{txt.logout}</span>
                </div>
            </div>
        )
    }
}

export default Navbar