import React, { Component } from 'react'
import swal from 'sweetalert'

import api from '../../services/api'
import headers from '../../common/parameters/headers'
import msg from '../../common/strings/messages'
import txt from '../../common/strings/appStrings'
import btnStrings from '../../common/strings/btnStrings'
import Header from '../../components/header'
import Footer from '../../components/footer'

import '../profileSettings/profileSettings.css'

class ProfileSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            profile: '',
            firstName: '',
            lastName: '',
            email: '',
            newPassword: '',
            confirmPassword: '',
            passwordMatch: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCancelAccount = this.handleCancelAccount.bind(this)
    }

    componentDidMount() {
        this.getUser()
    }

    handleChange(event) {
        this.setState({ profile: event.target.value, [event.target.name]: event.target.value })
    }

    handleConfirmPassword = e => {
        this.setState({ [e.target.name]: e.target.value })
        if (e.target.value === this.state.newPassword) {
            this.setState({ passwordMatch: true })            
        }
        else{
            this.setState({ passwordMatch: false })
        }
    }

    handleSubmit(values) {
        values.preventDefault()
    }

    refreshPage = () => {
        window.location.reload(true)
    }

    async updateUser() {
        const passwordMatch = this.state.passwordMatch
        const { history } = this.props

        if((this.state.newPassword || this.state.confirmPassword) && passwordMatch !== true) {
            swal({
                title: msg.titleUpdateUserError,
                text: msg.passwordValidate,
                icon: 'error',
                buttons: {
                    confirm: true
                }
            }).then(resp => {
                this.setState({
                    newPassword: '',
                    confirmPassword: ''
                })
            })

            return
        }

        const accountPassword = document.createElement('input')
        accountPassword.type = 'password'
        accountPassword.placeholder = 'Senha'
        accountPassword.setAttribute('class', 'swal-content__input')

        accountPassword.addEventListener('mouseover', function() {
            this.focus()
        })
        
        swal({
          title: msg.titleUpdateAccountInfoConfirm,
          text:msg.updateAccountInfoConfirm,
          content: accountPassword,
          icon: 'warning',
          dangerMode: true,
          buttons: [btnStrings.btnCancel, btnStrings.btnConfirmUpdateAccount]
        })
        .then((willUpdateAccountInfo) => {
          if(willUpdateAccountInfo) {
            if(accountPassword.value === null || accountPassword.value === ''){
                swal({
                    title: msg.noPasswordProvidedTitle,
                    text: msg.noPasswordProvidedMessage,
                    icon: 'warning',
                    buttons: {
                      confirm: true
                    }
                })
                return
            }
            
            let data = {
                email: this.state.email,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                currentPassword: accountPassword.value,
                newPassword: this.state.newPassword
            }
            data = JSON.parse(JSON.stringify(data).replace(/""/g, null))

            api.put('account', data, {
                headers: {
                    'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                    'Accept': headers.Accept,
                    'Content-Type': headers.Content_Type
                }
            }).then(resp => {
                if (resp.status === 200) {
                    if(this.state.firstName) {
                        localStorage.setItem('firstName', JSON.stringify(this.state.firstName))
                    }

                    if (resp.data.code === msg.userFieldsUpdatedAndConfirmationEmailSent) {
                        swal({
                            title: msg.titleUpdateUser,
                            text: msg.userFieldsUpdatedAndConfirmationEmailSentMsg,
                            icon: 'success',
                            buttons: {
                                confirm: true
                            }
                        }).then(resp => {
                            history.push('/')
                        })

                    }
                    else if (resp.data.code === msg.userFieldsUpdated) {
                        swal({
                            title: msg.titleUpdateUser,
                            text: msg.userFieldsUpdatedMsg,
                            icon: 'success',
                            buttons: {
                                confirm: true
                            }
                        }).then(resp => {
                            history.push('/')
                        })
                    }
                    else if (resp.data.code === msg.confirmationEmailSent) {
                        swal({
                            title: msg.titleUpdateUser,
                            text: msg.confirmationEmailSentMsg,
                            icon: 'success',
                            buttons: {
                                confirm: true
                            }
                        }).then(resp => {
                            history.push('/')
                        })
                    }
                }
            }).catch(error => {
                if (error.response && error.response.status === 400) {
                    if (error.response.data.code === msg.invalidPassword) {
                        swal({
                            title: msg.titleUpdateUserError,
                            text: msg.invalidPasswordMsg,
                            icon: 'error',
                            buttons: {
                                confirm: true
                            }
                        }).then(resp => {
                            this.setState({
                                newPassword: '',
                                confirmPassword: ''
                            })
                        })
                    }
                    else if (error.response.data.code === msg.emailAlreadyInUse) {
                        swal({
                            title: msg.titleUpdateUserError,
                            text: msg.emailInUse,
                            icon: 'error',
                            buttons: {
                                confirm: true
                            }
                        }).then(resp => {
                            this.setState({
                                newPassword: '',
                                confirmPassword: ''
                            })
                        })
                    }
                    else if (error.response.data.code === msg.invalidTokenAccess) {
                        swal({
                            title: msg.titleUpdateUserError,
                            text: msg.invalidToken,
                            icon: 'error',
                            buttons: {
                                confirm: true
                            }
                        }).then(resp => {
                            this.setState({
                                newPassword: '',
                                confirmPassword: ''
                            })
                        })
                    }
                    else if (error.response.data.code === msg.tokenAccessExpired) {
                        swal({
                            title: msg.titleUpdateUserError,
                            text: msg.tokenExpired,
                            icon: 'error',
                            buttons: {
                                confirm: true
                            }
                        }).then(resp => {
                            history.push('/login')
                        })
                    }
                }
                else {
                    swal({
                        title: msg.titleUpdateUserError,
                        text: msg.serverTrouble,
                        icon: 'error'
                    })
                }
            })

          }
        });
    }

    handleCancelAccount(e) {
        e.preventDefault()

        const accountPassword = document.createElement('input')
        accountPassword.type = 'password'
        accountPassword.placeholder = 'Senha'
        accountPassword.setAttribute('class', 'swal-content__input')

        accountPassword.addEventListener('mouseover', function() {
            this.focus()
        })

        swal({
            title: msg.titleCancelAccountConfirm,
            text:msg.cancelAccountConfirm,
            content: accountPassword,
            icon: 'warning',
            dangerMode: true,
            buttons: [btnStrings.btnCancel, btnStrings.btnDeleteAccount]
        })
        .then((willCancelAccount) => {
            if(willCancelAccount === false) {
                return
            }

            if(accountPassword.value === null || accountPassword.value === ''){
                swal({
                    title: msg.noPasswordProvidedTitle,
                    text: msg.noPasswordProvidedMessage,
                    icon: 'warning',
                    buttons: {
                      confirm: true
                    }
                })
                return
            }

            this.cancelAccount(accountPassword.value)
        })
    }

    async cancelAccount(accountPassword) {
        const { history } = this.props
        
        var data = {
            'password': accountPassword
        }

        await api.delete('account', {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Accept': headers.Accept,
                'Content-Type': headers.Content_Type
            },
            data: data

        }).then(resp => {
            swal({
                title: msg.titleDeleteAccount,
                text: msg.deleteAccount,
                icon: 'success',
                buttons: {
                    confirm: true
                }
            }).then(resp => {
                localStorage.clear()
                history.push('/login')
            })
        }).catch(error => {
            if (error.response && error.response.status === 400) {
                if (error.response.data.code === msg.invalidTokenAccess) {
                    swal({
                        title: msg.titleUpdateUserError,
                        text: msg.invalidToken,
                        icon: 'error'
                    }).then(resp => {
                        history.push('/login')
                    })
                }
                else if (error.response.data.code === msg.tokenAccessExpired) {
                    swal({
                        title: msg.titleUpdateUserError,
                        text: msg.tokenExpired,
                        icon: 'error',
                        buttons: {
                            confirm: true
                        }
                    }).then(resp => {
                        history.push('/login')
                    })
                }
                else if (error.response.data.code === msg.incorrectEmailOrPw) {
                    swal({
                        title: msg.titleLoginError,
                        text: msg.emailOrPwError,
                        icon: 'error',
                        buttons: {
                            confirm: true
                        }
                    })
                }
                else {
                    swal({
                        title: msg.titleUnknownError,
                        text: msg.unknownError,
                        icon: 'error',
                        buttons: {
                            confirm: true
                        }
                    })
                }
            }
        })
    }

    async getUser() {
        await api.get('account', {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Accept': headers.Accept,
                'Content-Type': headers.Content_Type
            }
        }).then(resp => {
            const profile = resp.data

            this.setState({ profile: profile })
        }).catch(function (error) {
            if (error.response && error.response.status === 400) {
                swal({
                    title: msg.titleError,
                    text: msg.invalidTokenAccess,
                    icon: 'error'
                })
            }
            else {
                swal({
                    title: msg.titleError,
                    text: msg.serverTrouble,
                    icon: 'error'
                })
            }
        })
    }

    render() {
        return (
            <section id='profile-section'>
                <Header />
                <div id='body'>
                    <article>
                        <form className='form-profile' onSubmit={this.handleSubmit}>
                            <legend className='col-sm-4'>{txt.userProfile}</legend>

                            <div className='form-row f'>
                                <div className='form-group col-xs-12 col-sm-6 col-md-4'>
                                    <label>{txt.firstName}</label>
                                    <input type='input' name='firstName' onChange={this.handleChange}
                                        value={this.state.profile.firstName} />
                                </div>

                                <div className='form-group col-xs-12 col-sm-6 col-md-4'>
                                    <label>{txt.lastName}</label>
                                    <input type='input' name='lastName' onChange={this.handleChange}
                                        value={this.state.profile.lastName} />
                                </div>

                                <div className='form-group col-xs-12 col-sm-6 col-md-4'>
                                    <label>{txt.email}</label>
                                    <input type='email' name='email' onChange={this.handleChange}
                                        value={this.state.profile.email} />
                                </div>
                            </div>

                            <div className='dropdown-divider'></div>

                            <legend className='col-sm-4'>{txt.editPass}</legend>

                            <div className='form-row f'>
                                <div className='form-group col-xs-12 col-sm-4 col-md-4'>
                                    <label>{txt.newPass}</label>
                                    <input type='password' name='newPassword' placeholder='Nova Senha'
                                        onChange={this.handleChange} value={this.state.newPassword} />
                                </div>

                                <div className='form-group col-xs-12 col-sm-4 col-md-4'>
                                    <label>{txt.confirmPass}</label>
                                    <input type='password' name='confirmPassword' placeholder='Confirmar Senha'
                                        onChange={this.handleConfirmPassword} value={this.state.confirmPassword} />
                                </div>
                            </div>
                            <hr/>
                            <div className='form-row'>
                                <div className='form-group col-xs-12 col-sm-6 col-md-6'>
                                    <button className='btn btn-cancel' onClick={() => this.refreshPage()}>{btnStrings.btnCancel}</button>
                                    <button className='btn btn-save'
                                        onClick={() => this.updateUser()}>
                                        {btnStrings.btnSave}
                                    </button>
                                </div>

                                <div className='form-group col-xs-12 col-sm-4 col-md-5 text-right'>
                                    <button className='btn btn-delete-account' onClick={this.handleCancelAccount}>{btnStrings.btnCancelAccount}</button>
                                </div>

                            </div>
                        </form>
                    </article>
                </div>
                <Footer />
            </section>
        )
    }
}

export default ProfileSettings