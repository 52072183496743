import React from 'react'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { GuardProvider, GuardedRoute } from 'react-router-guards'

import api from './services/api'
import headers from './common/parameters/headers'

import Login from './pages/authentication/auth'
import AccountRecover from './pages/accountRecover/recover'
import MainPage from './pages/mainPage/mainPage'
import ProjectList from './pages/projectList/projectList'
import ProjectDetail from './pages/projectDetail/projectDetail'
import ProfileSettings from './pages/profileSettings/profileSettings'
import Billing from './pages/billing/billing'
import NotFound from './pages/notFound/notFound'

const history = createBrowserHistory()

const requireLogin = async (to, from, next) => {
  if (localStorage !== undefined) {
    await api.get('account', {
      headers: {
        'tokenAccess': localStorage.getItem('tokenAccess').toString(),
        'Accept': headers.Accept,
        'Content-Type': headers.Content_Type
      }
    }).then(resp => {
      next()
    })
  }
  else {
    next.redirect('/login')
  }
}

const Routes = () => {
  return (
    <Router history={history}>
      <GuardProvider error={NotFound} >
        <GuardedRoute path='/login' exact component={Login} />
        <GuardedRoute path='/verify/:token' exact component={Login} />
        <GuardedRoute path='/recover/:token' exact component={AccountRecover} />
        <GuardProvider guards={[requireLogin]}>
          <GuardedRoute path='/' exact component={MainPage} />
          <GuardedRoute path='/projectlist' exact component={ProjectList} />
          <GuardedRoute path='/projectDetail' exact component={ProjectDetail} />
          <GuardedRoute path='/profilesettings' exact component={ProfileSettings} />
          <GuardedRoute path='/billing' exact component={Billing} />
        </GuardProvider>
      </GuardProvider>
    </Router>
  )
}

export default Routes
