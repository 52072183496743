import React, { Component } from 'react'
import swal from 'sweetalert'
import FileSaver from 'file-saver'

import api from '../../services/api'
import headers from '../../common/parameters/headers'
import msg from '../../common/strings/messages'
import btnStrings from '../../common/strings/btnStrings'
import txt from '../../common/strings/appStrings'
import Header from '../../components/header'
import Footer from '../../components/footer'
import defaultIcon from '../../assets/app-default-icon.png'

import './projectDetail.css'

function order(a, b) {
    if (a.updateTimestamp > b.updateTimestamp) return -1
    if (a.updateTimestamp < b.updateTimestamp) return 1
    return 0

}

class ProjectDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            executionList: [],
            application_name: '',
            package_name: '',
            scans: '',
            queue: '',
            oldReportSelected: null,
            newReportSelected: null,
            diffButtonDisabled: true,
            generating_diff_report: false,
            generating_result_reports: [],
            updateScheduler: null
        }
    }

    componentDidMount() {
        this.scheduleExeuctionUpdateChecker()
        this.getProjectDetail()
    }

    componentWillUnmount() {
        this.removeSchedulerIfExists()
    }

    scheduleExeuctionUpdateChecker() {
        this.removeSchedulerIfExists()

        if(localStorage.getItem('lastUpdateStoredData')) {
            localStorage.removeItem('lastUpdateStoredData')
        }

        this.updateChecker()
    }

    async updateChecker() {
        var lastUpdateStoredData = localStorage.getItem('lastUpdateStoredData')
        
        if(!localStorage.getItem('projectID')) {
            return
        }

        const projectID = localStorage.getItem('projectID')
        await api.get(`project/${projectID}/execution`, {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Content-Type': headers.Content_Type
            }
        }).then(resp => {
            var data = JSON.stringify(resp.data)
            if(data !== lastUpdateStoredData) {
                if(!lastUpdateStoredData){
                    localStorage.setItem('lastUpdateStoredData', data)
                }
                else{
                    localStorage.setItem('lastUpdateStoredData', data)
                    this.getProjectDetail()
                }
            }
        }).catch(error => {
            if (error.response && error.response.status === 400) {
                if (error.response.data.code === msg.invalidTokenAccess) {
                    window.location.href = '/login'
                }
                else if (error.response.data.code === msg.tokenAccessExpired) {
                    window.location.href = '/login'
                }
            }
        })

        if(localStorage.getItem('projectID') && this.shouldReschedule()) {
            var sched = setTimeout(
                function() {
                    this.updateChecker()
                }
                .bind(this)
                , 10000
            )

            this.setState({updateScheduler: sched})
        }
    }

    shouldReschedule() {
        var lastUpdateStoredData = localStorage.getItem('lastUpdateStoredData')

        if(lastUpdateStoredData) {
            var data = JSON.parse(lastUpdateStoredData)

            if(data && data.executionList) {
                var executionList = data.executionList
                for(var i = 0; i < executionList.length; i++) {
                    if(executionList[i].status !== 'finished' && executionList[i].status !== 'failed') {
                        return true
                    }
                }
            }
            else{
                return true
            }
        }
        else{
            return true
        }

        return false
    }

    async getProjectDetail() {
        const { history } = this.props

        const projectID = localStorage.getItem('projectID')
        const application_name = localStorage.getItem('apkName')
        const package_name = localStorage.getItem('packageName')
        const scans = localStorage.getItem('scans')
        const queue = localStorage.getItem('queue')

        this.setState({ application_name: application_name, package_name: package_name, scans: scans, queue: queue })

        await api.get(`project/${projectID}/execution`, {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Content-Type': headers.Content_Type
            }
        }).then(resp => {
            const executionList = resp.data.executionList.sort(order)

            this.setState({ executionList: executionList })
        }).catch(error => {
            if (error.response && error.response.status === 400) {
                if (error.response.data.code === msg.invalidTokenAccess) {
                    this.setState({ loadButton: false })
                    swal({
                        title: msg.titleErrorNewVersion,
                        text: msg.invalidToken,
                        icon: 'error',
                        buttons: {
                            confirm: true
                        }
                    }).then(resp => {
                        window.location.reload(true)
                    })
                }
                else if (error.response.data.code === msg.tokenAccessExpired) {
                    this.setState({ loading: false })
                    swal({
                        title: msg.titleErrorProject,
                        text: msg.tokenExpired,
                        icon: 'error',
                        buttons: {
                            confirm: true
                        }
                    }).then(resp => {
                        history.push('/login')
                    })
                }
            }
            else {
                this.setState({ loadButton: false })
                swal({
                    title: msg.titleErrorNewVersion,
                    text: msg.serverTrouble,
                    icon: 'error',
                    buttons: {
                        confirm: true
                    }
                }).then(resp => {
                    window.location.reload(true)
                })
            }
        })
    }

    async getResult(executionId, version) {
        const type = 'pdf'

        const dt = Date.now()
        const timestamp = new Intl.DateTimeFormat('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(dt)

        this.setState({
            generating_result_reports: this.state.generating_result_reports.concat([executionId])
        })

        await api.get(`report/${executionId}/${type}`, {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Content-Type': headers.Content_Type
            },
            responseType: 'blob'
        }).then(resp => {
            const file = new File([resp.data], `${this.state.application_name} v.${version} - ${timestamp}.${type}`)
            FileSaver.saveAs(file)
        }).finally(() => {
            this.setState({
                generating_result_reports: this.state.generating_result_reports.filter(item => item !== executionId)
            })
        })
    }

    async getReportDiff() {
        const type = 'pdf'

        const dt = Date.now()
        const timestamp = new Intl.DateTimeFormat('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(dt)

        var oldReportId = this.state.oldReportSelected.getAttribute('name')
        var newReportId = this.state.newReportSelected.getAttribute('name')
        
        this.setState({generating_diff_report: true})
        await api.get(`report/diff/${oldReportId}/${newReportId}/${type}`, {
            headers: {
                'tokenAccess': localStorage.getItem('tokenAccess').toString(),
                'Content-Type': headers.Content_Type
            },
            responseType: 'blob'
        }).then(resp => {
            const file = new File([resp.data], `${this.state.application_name} diff ${timestamp}.${type}`)
            FileSaver.saveAs(file)
        }).finally(() => {
            this.setState({generating_diff_report: false})
        })
    }

    removeSchedulerIfExists() {
        if(this.state.updateScheduler) {
            clearTimeout(this.state.updateScheduler)
            this.setState({updateChecker: null})
        }
    }

    backToProjectList = () => {
        const { history } = this.props

        this.removeSchedulerIfExists()

        localStorage.removeItem('packageName')
        localStorage.removeItem('projectID')
        localStorage.removeItem('scans')
        localStorage.removeItem('apkName')
        localStorage.removeItem('queue')

        history.push('/projectList')
    }

    checkboxClickHandler = (event) => {
        if(event.target.checked) {
            if(this.state.oldReportSelected && this.state.newReportSelected) {
                event.target.checked = false
                return
            }
            if(this.state.oldReportSelected == null && this.state.newReportSelected == null) {
                this.setState({oldReportSelected: event.target})
            }
            else if(this.state.oldReportSelected == null) {
                this.setState({oldReportSelected: event.target})
            }
            else if(this.state.newReportSelected == null) {
                this.setState({newReportSelected: event.target})
            }
        }
        else{
            if(event.target === this.state.oldReportSelected) {
                this.setState({oldReportSelected: null})
            }
            else if(event.target === this.state.newReportSelected) {
                this.setState({newReportSelected: null})
            }
        }
    }

    render() {
        return (
            <div className='div-project-detail'>
                <Header />
                <div id='body'>
                    <section id='details-section'>
                        <article>
                            <header>
                                <span onClick={this.backToProjectList} type='button' className='btn-back'>
                                    <i className='fa fa-arrow-left'></i>
                                </span>
                                <div className='apkInfo'>
                                    <div className='apkIcon'>
                                        {localStorage.getItem('icon-' + localStorage.getItem('projectID')) ? 
                                            <img className='app-icon' alt='app-icon' src={`data:image/png;base64, ${localStorage.getItem('icon-' + localStorage.getItem('projectID'))}`} width="96px" height="96px" />
                                            :
                                            <img className='app-icon' alt='app-icon' src={defaultIcon} width="96px" height="96px" />
                                        }
                                    </div>

                                    <div className='apkName'>
                                        <span className='info'><strong>{this.state.application_name}</strong></span>
                                        <span className='sub-info'>{this.state.package_name}</span>
                                    </div>

                                    <div className='apkAnalisysInfo'>
                                        <span><strong>{txt.totalScans}</strong> {this.state.scans}</span>
                                        <span><strong>{txt.status}</strong> {this.state.queue} na fila</span>
                                    </div>
                                </div>
                            
                                <div className='ml-auto'>
                                    {
                                        this.state.generating_diff_report ?
                                            <button className='btn btn-md btn-compare' disabled='disabled'>
                                                <i className='fa fa-refresh fa-spin'></i>&nbsp;&nbsp;{txt.generating_report}
                                            </button>
                                        :
                                            <button className='btn btn-md btn-compare' disabled={!this.state.oldReportSelected || !this.state.newReportSelected} onClick={() => this.getReportDiff()}>
                                                <i className='fa fa-files-o'></i>&nbsp;&nbsp;{btnStrings.btnCompareProjects}
                                            </button>
                                    }
                                </div>
                            </header>

                            <legend className='legend-analisis'>{txt.legendProjectDetails}</legend>
                            
                                {this.state.executionList.map(list => (
                                    <div key={list.executionId}>
                                        <div className='details-body'>
                                            <div className='apk-checkbox'>
                                                {list.status === 'finished' ?
                                                    <input type='checkbox' name={list.executionId} onClick={this.checkboxClickHandler}/>
                                                    : 
                                                    <input type='checkbox' hidden={true}/>
                                                }
                                            </div>
                                            <div className='apk-info'>
                                                <span className='info-analisis'><strong>{txt.package}</strong> {list.package_name}</span>
                                                <span className='info-analisis'><strong>{txt.version}</strong> {list.package_version}</span>
                                                <span className='info-analisis'><strong>{txt.scan_date}</strong> {list.scan_date}</span>
                                            </div>

                                            <div className='details-info'>
                                                <span><strong>{txt.exported_component}</strong> {list.total_exported_components}</span>
                                                <span><strong>{txt.vulnerabilities}</strong> {list.total_vulnerabilities}</span>
                                            </div>

                                            <div className='details-button'>
                                                {list.status === 'uploaded' ?
                                                    <div className='wait-info body1'>
                                                        <span><strong>{txt.uploaded} </strong><i className='fa fa-refresh fa-spin'></i></span>
                                                        <span className='body2'>&nbsp;&nbsp;{txt.take_while}</span>
                                                    </div>
                                                    : list.status === 'sent_to_analyser' ?
                                                        <div className='wait-info body1'>
                                                            <span><strong>{txt.sent_to_analyser} </strong><i className='fa fa-refresh fa-spin'></i></span>
                                                            <span className='body2'>&nbsp;&nbsp;{txt.take_while}</span>
                                                        </div>
                                                        : list.status === 'running' ?
                                                            <div className='wait-info body1'>
                                                                <span><strong>{txt.running} </strong><i className='fa fa-refresh fa-spin'></i></span>
                                                                <span className='body2'>&nbsp;&nbsp;{txt.take_while}</span>
                                                            </div>
                                                            : list.status === 'finished' ?
                                                                this.state.generating_result_reports.includes(list.executionId) ?
                                                                    <button className='btn btn-dark btn-md btn-results' disabled='disabled'>
                                                                        <i className='fa fa-refresh fa-spin'></i>&nbsp;&nbsp;{txt.wait}
                                                                    </button>
                                                                :
                                                                    <button className='btn btn-dark btn-md btn-results' disabled={this.state.oldReportSelected || this.state.newReportSelected} onClick={() => this.getResult(list.executionId, list.package_version)}>
                                                                        <i className='fa fa-download'></i>&nbsp;&nbsp;{btnStrings.btnResults}
                                                                    </button>
                                                                :
                                                                <div className='wait-info body1'>
                                                                    <span><strong>{txt.failed} </strong><i className='fa fa-refresh fa-exclamation-triangle'></i></span>
                                                                    <span className='body2'>&nbsp;&nbsp;{txt.analysis_error}</span>
                                                                </div>
                                                }
                                            </div>
                                        </div>
                                        <div className='line-divider'></div>
                                    </div>
                                ))}
                            
                        </article>
                    </section>
                </div>
                <Footer />
            </div>
        )
    }
}

export default ProjectDetail